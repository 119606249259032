<template>
  <component :is="'b-card'">
    <b-tabs pills>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('admin.general.title') }}</span>
        </template>
        <GeneralSettings class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('admin.contacts.title') }}</span>
        </template>
        <ContactsSettings class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BCard, BTab, BTabs,
} from 'bootstrap-vue'
import ContactsSettings from './ContactsSettings.vue'
import GeneralSettings from './GeneralSettings.vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    GeneralSettings,
    ContactsSettings,
  },
}
</script>
