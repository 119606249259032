<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.phone.label')"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="data.phone"
              :state="errors && errors.phone ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.phone">
              {{ errors.phone[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.email.label')"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="data.email"
              :state="errors && errors.email ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.email">
              {{ errors.email[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.viber.label')"
            label-for="viber"
          >
            <b-form-input
              id="viber"
              v-model="data.viber"
              :state="errors && errors.viber ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.viber">
              {{ errors.viber[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.telegram.label')"
            label-for="telegram"
          >
            <b-form-input
              id="telegram"
              v-model="data.telegram"
              :state="errors && errors.telegram ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.telegram">
              {{ errors.telegram[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.signal.label')"
            label-for="signal"
          >
            <b-form-input
              id="signal"
              v-model="data.signal"
              :state="errors && errors.signal ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.signal">
              {{ errors.signal[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.facebook.label')"
            label-for="facebook"
          >
            <b-form-input
              id="facebook"
              v-model="data.facebook"
              :state="errors && errors.facebook ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.facebook">
              {{ errors.facebook[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.instagram.label')"
            label-for="instagram"
          >
            <b-form-input
              id="instagram"
              v-model="data.instagram"
              :state="errors && errors.instagram ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.instagram">
              {{ errors.instagram[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.youtube.label')"
            label-for="youtube"
          >
            <b-form-input
              id="youtube"
              v-model="data.youtube"
              :state="errors && errors.youtube ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.youtube">
              {{ errors.youtube[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      data: null,
    }
  },
  async beforeCreate() {
    await this.$http('/api/admin/settings', { params: { group: 'contacts' } }).then(response => {
      this.data = response.data
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/contacts', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
